$gray: #9F9F9F;

.table {
  font-size: 16px;

  tr {
    th {
      border-top: none;
      font-weight: 800;
      color: $gray;
    }
  }

  thead {
    th {
      border-bottom: 1px solid #dee2e6;
      font-size: 14px;
    }
  }
}