.login {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .login-container {
    width: 345px;
    box-shadow: 1px 1px 13px 1px #e7e7e7;
    border-radius: 5px;
    padding: 30px;

    form {
      text-align: center;
      padding-bottom: 0;
    }
  }
}