$border-gray  : #E3E3E3 !default;
$small-screen : 767px;

.dialogContainer {
  @at-root #{&}__head {
    padding: 20px 30px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }

  @at-root #{&}__content {
    font-size: 16px;
    padding: 20px 30px;
    min-width: 300px;
    width: 500px;
    text-align: center;

    @media (max-width: $small-screen) {
      width: 100%;
      max-width: unset;
      min-width: unset;
    }
  }

  @at-root #{&}__foot {
    width: 100%;
    padding: 20px 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.form-control {
  font-size: 15px;
}