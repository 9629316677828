.menu {
  position: fixed;
  top: 0;
  right: 0;
  padding: 2rem;
  height: 100%;
  background: #F9F9F9;
  text-align: left;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  z-index: 10;

  @media (max-width: 600px) {
    left: 0;
    align-items: center;
  }

  a {
    font-size: 20px;
    padding: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #474747;
    text-decoration: none;
    transition: color 0.3s linear;
  }

  div.button-logout {
    padding: 1.2rem 0;
  }
}

.menu-open {
  transform: translateX(0) !important;
}