.view-applicant {
  padding-top: 40px;

  @media (max-width: 600px) {
    padding-top: 18px;
  }

  @at-root #{&}__content {
    display: flex;
    flex-direction: row;

    @media (max-width: 992px) {
      flex-direction: column;

      .left-column, .right-column {
        max-width: 100%;
      }
    }

    @media (max-width: 600px) {
      .left-column, .right-column {
        padding-left: 0;
        padding-right: 0;
      }

      .right-column {
        max-width: 100%;

        .comment-box {
          flex-direction: column !important;

          .comment-box-textarea {
            padding-right: 0 !important;
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  @at-root #{&}__breadcrumb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 600px) {
      flex-direction: column;
      
      span.accept-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        margin-top: 15px;
      }
    }
  }
}