@font-face {
  font-family: 'Greycliff';
  src: url('../Fonts/GreycliffCF-Regular.eot') format('eot');
  src: url('../Fonts/GreycliffCF-Regular.woff') format('woff'),
       url('../Fonts/GreycliffCF-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../Fonts/Avenir.eot') format('eot');
  src: url('../Fonts/Avenir.woff') format('woff'),
       url('../Fonts/Avenir.ttf') format('truetype'),
       url('../Fonts/Avenir.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Saira';
    src: url('../Fonts/Saira.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../Fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../Fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../Fonts/Lato/Lato-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}