.home-view {
  padding-top: 40px;

  @media (max-width: 600px) {
    padding-top: 18px;
  }
  
  @at-root #{&}__content {
    display: flex;
    flex-direction: row;

    @media (max-width: 600px) {
      flex-direction: column;

      .home-view-sidebar {
        margin-bottom: 28px;
      }
  
      .home-view-post {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 28px 0;

        .post-list-label {
          flex-direction: column !important;
        }

        .post-list-label > span {
          font-size: 12px !important;
          padding-left: 0 !important;
          margin-bottom: 8px;
        }

        .breadcrumb {
          padding: 0 15px;
        }
      }
    }
  }
}