.payment-view {
  @at-root #{&}__content {
    display: flex;
    flex-direction: row;

    .payment-view-tbl {
      width: calc(100% - 187px);

      .tbl-container {
        overflow: auto;
    
        .table {
          min-width: 670px;
        }
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
      
      .payment-view-filter {
        margin-bottom: 28px;
      }

      .payment-view-tbl {
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 28px 0;
      }
    }
  }
}