.search-dropdown{
  list-style: none;
  padding: 0;
  border: 1px solid #ced4da;
  border-top: 0;
  margin-top: -20px;
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 3;

  li {
    cursor: pointer;
    padding: 3px 8px;
    
    &:hover {
      background: #ced4da;
    }
  }
}