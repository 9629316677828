.student-detail {
  background: #DCDCDC;
  border-radius: 1px;
  padding: 5px 10px;
  position: relative;
  font-size: 12px;
  text-transform: capitalize;
}

.student-detail + .student-detail {
  margin-left: 3px;
}