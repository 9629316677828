$text-blue: #1C77FF !default;
$text-gray: #9F9F9F !default;

.page-sidebar {
  @at-root #{&}__title {
    font-size: 18px;
    text-transform: uppercase;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 5px 0;
      font-size: 16px;
      color: $text-gray;
      font-weight: 500;
      cursor: pointer;

      &.active {
        color: $text-blue;
      }

      &:hover {
        color: $text-blue;
      }
    }
  }
}