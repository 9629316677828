$banner-bg   : #EBF1F6 !default;

// BORDER COLOR
$border-gray : #E4E4E4 !default;

// COLOR
$prime-text  : #2FBAE5 !default;
$blue-text   : #007bff !default;
$black-text  : #000000 !default;
$gray-text   : #AEAEAE !default;
$grey-text   : #CECECE !default;
$red-text    : #BF0000 !default;
$green-text  : #00BF13 !default;

.hidden {
  display: none;
}

.width-max {
  width: 100%;
}

.top-index {
  z-index: 999999;
}

.position-relative {
  position: relative;
}

.comment-section {
  padding-top: 25px;
  position: relative;
  padding-bottom: 100px;
}

.banner-container {
  padding: 20px 0;
  background: $banner-bg;

  .display-4 {
    font-weight: bold;
    font-size: 24px;
  }

  &.no-bg {
    background: none;
  }

  &.w-break {
    border-bottom: 1px solid $border-gray;
    margin-bottom: 40px;
    padding-bottom: 0;
  }
}

.content-limit {
  font-size: 16px;
  line-height: 1.5em;
  min-height: 2em;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

.status-container {
  padding: 3px 5px;
  border-radius: 3px;
  font-size: 5px;
  margin-top: 5px;
}

// SPACE 
.space-top-30 {
  margin-top: 30px;
}

// FONT SIZE 
.font-30 {
  font-size: 30px !important;
}

.font-25 {
  font-size: 25px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-10 {
  font-size: 10px !important;
}

// COLORS
.color-link {
  color: $blue-text;
}

.color-prime {
  color: $prime-text;
}

.color-gray {
  color: $gray-text;
}

.color-grey {
  color: $grey-text;
}

.color-black {
  color: $black-text;
}

.color-red {
  color: $red-text;
}

.color-green {
  color: $green-text;
}

// WEIGHT
.font-bold {
  font-weight: bold;
}

// TEXT TRANSFORM
.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

// TEXTAREA
.resize-none {
  resize: none;
}

// CURSOR
.c-pointer {
  cursor: pointer;
}

.no-hover-style:hover {
  text-decoration: none;
}

// PADDING
.main-padding {
  padding: 0 20px;

  @media (max-width: 600px) {
    padding: 0 18px
  }
}

.pad-right-30 {
  padding-right: 30px;
}

.pad-right-20 {
  padding-right: 20px;
}

.pad-right-70 {
  padding-right: 70px;
}

.pad-right-80 {
  padding-right: 80px;
}

// WORD BREAK
.word-break {
  word-break: break-all;
}

// BUTTONS
.btn-info.disabled, .btn-info:disabled {
  opacity: 0.4;
  background-color: #1C77FF;
  border-color: #1C77FF;
  pointer-events: none;
}

.button-text {
  color: #1890ff;
  cursor: pointer;
}
