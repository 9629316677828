$black: #2E2E2E !default;
$white: #ffffff !default;
$gray:  #9B9B9F !default;
$blue:  #1C77FF !default;

.header {
  background: $white;
  padding: 18px;
  box-shadow: 0px 4px 4px rgba(167, 167, 167, 0.25);

  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;

  @at-root #{&}__container {
    .header-brand {
        width: 105px;
        h4 {
          margin: 0;
          font-size: 16px;
        }

        &:hover {
          text-decoration: none;
        }
    }

    .header-search {
      width: 250px;
      margin: 0 40px;
      position: relative;

      display: flex;
      align-items: center;

      @media (max-width: 600px) {
        margin: 0;
        margin-top: 10px;
      }

      .input-container {
        width: 100%;

        @media (max-width: 600px) {
          width: calc(100% - 32px);
        }
      }

      input {
        font-size: 16px;
        max-height: 28px;
        padding-left: 30px;
      }

      .input-autocomplete {
        position: fixed;
        height: calc(100% - 64px);
        width: 100%;
        left: 0;
        top: 65px;
        background: #ffffff;
        padding: 18px;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;

        @media (max-width: 600px) {
          top: 94px;
          height: calc(100% - 94px);
        }

        .fillers {
          width: 105px;

          @media (max-width: 600px) {
            display: none;
          }
        }

        .options {
          width: calc(100% - 105px);
          padding-left: 40px;
          overflow: auto;

          @media (max-width: 600px) {
            padding-left: 0;
            width: 100%;
          }

          a.option {
            cursor: pointer;
            color: #656565;
            font-size: 16px;
            line-height: 2;
            -webkit-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
            width: fit-content;

            &:hover {
              -webkit-transform: scale(1.05);
              transform: scale(1.05);
              text-decoration: none;
            }
          }
        }
      }

      .search-icon {
        position: absolute;
        top: 7px;
        left: 9px;
        font-size: 12px;
        color: #656565;
      }
    }

    a.active,
    .header-brand {
        color: $black;
        font-weight: bold;
        h4{
          font-weight: bold;
        }
    }

    a {
      color: $gray;
    }
  }

  @at-root #{&}__menu {
    display: none;

    a {
        padding: 0 16px;
        font-size: 16px;
    }

    @media (min-width: 1172px) {
      display: block;
    }
  }

  @at-root #{&}__logo {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @at-root #{&}__close-icon {  
    cursor: pointer;
    font-size: 22px;
    color: #656565;
  }
}