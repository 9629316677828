.section-view {
  display: flex;
  flex-direction: row;

  // @at-root #{&}__left {
  //   width: calc(100% - 300px);
  // }

  // @at-root #{&}__right {
  //   width: 300px;
  // }
}