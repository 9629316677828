$gray    : #E4E4E4;
$white   : #FFFFFF;
$blue    : #006edc;
$bg-gray : #e6e6e6;

.calendar-override {
  border: none;
  font-family: Avenir;
  font-weight: 300;
  font-size: 14px;
  width: 320px;
  min-width: 300px;

  .react-calendar__navigation {
    margin-bottom: .5em;
  }

  .react-calendar__navigation__label {
    font-weight: bold;
  }

  .react-calendar__tile {
    border-radius: 50%;
    padding: 0.90em 0.5em;
  }

  .react-calendar__tile:disabled {
    background-color: transparent;
    color: #9F9F9F;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: #9F9F9F;
    abbr {
      text-decoration: none;
      cursor: default
    }
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__tile--now {
    border: 1px solid $gray;
    background: transparent;

    &:hover,
    &:focus {
      background: $bg-gray;
    }
  }

  .react-calendar__tile--active {
    color: $white;
    background: $blue;

    &:hover,
    &:focus {
      background: $blue;
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #9F9F9F;
  }
}

.calendar-no-width {
  width: unset !important;
}

