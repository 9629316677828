.hamburger {
  position: fixed;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 11;
  overflow: hidden;
  

  @media (min-width: 1172px) {
    display: none;
  }
  
  &:focus {
    outline: none;
  }
  
  div {
    width: 2rem;
    height: 0.25rem;
    background: #474747;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  div:first-child {
    transform: rotate(0);
  }

  div:nth-child(2) {
    opacity: 1;
    transform: translateX(0);
  }

  div:nth-child(3) {
    transform: rotate(0);
  }
}

.hamburger-open {
  div:first-child {
    transform: rotate(45deg) !important;
  }

  div:nth-child(2) {
    opacity: 0;
    transform: translateX(20px) !important;
  }

  div:nth-child(3) {
    transform: rotate(-45deg) !important;
  }
} 