.students-view {
  .tbl-container {
    overflow: auto;

    .table {
      min-width: 670px;
    }
  }

  .banner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 600px) {
      flex-direction: column;

      h4 {
        padding-bottom: 10px;
      }

      div {
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}