.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0, 0.1);
  z-index: -1;
  opacity: 0;

  &.active {
    position: fixed;
    opacity: 1;
    z-index: 999999;
  }

  .ant-spin{
    transform: scale(1.5);
  }
}