$bg-color: #ffffff !default;
.sidebar, .sidebar-admin{
  background: #F7F7F8;
  padding: 20px 0 !important;
  height: 100vh;
  position: relative;
  border-right: 1px solid #ECECEF;
  ul {
      padding: 0 8px;
      font-family: Lato,sans-serif;
      letter-spacing: calc(.05rem - .05em);
      font-weight: 600;
      li.ant-menu-item {
          outline: none !important;
          margin: 2px 0 !important;
          font-size: 12px !important;
          border-radius: 5px !important;
          * {
            color: hsla(0,0%,13%,0.6) !important;
          }

          &:not(.ant-menu-item-selected):hover {
            background: #E4E4E8 !important;
             * {
                color: hsla(0,0%,13%,1) !important;
             }
        }
      }
      li.ant-menu-item-selected{
        background: #E4E4E8 !important;
        border-radius: 5px !important;
        * {
            color: hsla(242,87.6%,62%,1) !important;
        }
          &::after {
              position: absolute;
              top: 9px !important;
              left: 10px !important;
              right: auto !important;
              height: 55% !important;
              bottom: 0;
              border-right: 0;
          }
        &:focus {
          border: 2px solid hsla(242,87.6%,62%,1) !important;
        }
      }
  }

  .ant-menu-item-group-title{
      font-size: 8px !important;
  }

  .sideToggle{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1;
    pointer-events: none;
  }

  .toggle-icon{
    padding: 5px;
    color: #1890ff;
    pointer-events: all;
    cursor: pointer;
  }

  .ant-menu-item {
    margin: 0 !important;
  }

  .logout{
    background: #625DF5;//linear-gradient(174.35deg, #536DD7 99.51%, #314FC8 0%);
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    z-index: 1;
    letter-spacing: calc(.05rem - .05em);
    padding: 10px 16px 10px 10px;
    color: #FFF;
    font-weight: 500;

    .logout-icon{
        cursor: pointer;
    }

    .profile-icon {
        color: hsla(242,87.6%,62%,1);
        background: #ffffff;
        width: 28px;
        height: 28px;
        border-radius: 50%;
    }

    &:hover {
      background: #342DF2;
  }
  }
}

