.attendance-view {
  @at-root #{&}__content {
    display: flex;
    flex-direction: row;
    
    div.container-fluid {
      width: calc(100% - 320px);

      .tbl-container {
        overflow: auto;
    
        .table {
          min-width: 850px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    @at-root #{&}__banner { 
      div {
        .banner-text {
          padding-bottom: 20px;
        }

        .banner-btn {
          padding-bottom: 40px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }

    @at-root #{&}__content {
      flex-direction: column;
      align-items: center;

      div.container-fluid {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
}