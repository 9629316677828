$bg-color-gray:  #F8F8F8 !default;
$white:          #FFFFFF !default;
$btn-background: rgba(28, 119, 255, 0.07);
$btn-color:      #1C77FF !default;
$bt-color-cancel: #1C77FF !default;

.jumbotron {
  background-color: $bg-color-gray;
  padding: 2rem 2rem;
}

.btn {
  font-size: 16px;
  padding: .375rem 1.2rem;
  border: none;
}

.btn-info {
  background: $btn-background;
  color: $btn-color;

  &:active,
  &:focus,
  &:hover {
    background: $btn-color;
    color: $white;
  }

  &:not(:disabled):not(.disabled):active {
    background-color: $btn-color;
    border: none;
  }

  &:focus {
    border: none;
    box-shadow: none;
    background: $btn-background;
    color: $btn-color;
  }
}

.btn-outline-info {
  color: #1C77FF;

  &:focus {
    border: none;
    box-shadow: none;
  }

  &:hover {
    background-color: #2FBAE5;
    border-color: #1C77FF;
  }
}

.top-index-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.54);
  z-index: 999999;
}

// ANTD OVERRIDES
.ant-breadcrumb a, .ant-breadcrumb > span:last-child {
  color: #AEAEAE !important;
}

.ant-breadcrumb a:hover {
  color: #40a9ff !important;
}

.ant-divider-horizontal {
  margin: 15px 0 !important;
}

.ant-table-thead > tr > th {
  color: #AEAEAE !important;
  background: transparent !important;
  font-size: 12px;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  display: flex !important;
  align-items: center;
}

.ant-pagination-options {
  display: none !important;
}

.anticon { // For pagination next 5 pages
  vertical-align: 0.175em !important;
}

.ant-menu-item {
  padding-left: 16px !important;
}

// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   background: transparent !important;
// }

// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:first-child {
//   background-color: transparent !important;
// }

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after, .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: none !important;
}

.ant-tag {
  font-size: 10px !important;
}

.ant-page-header-heading-tags {
  display: flex;
  align-items: center;
}

.ant-btn-default {
  color: #FFFFFF !important;
  background: #000000 !important;
  border-color: #000000 !important;
}

.ant-btn {
  border-radius: 5px !important;
  font-weight: 600 !important;
}

.ant-select-multiple .ant-select-selection-item-remove {
  display: flex !important;
  align-items: center !important;
}